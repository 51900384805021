import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/posts.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`What kind of sorcery is this?`}</h2>
    <p>{`HTTP Public Key Pinning, or short HPKP, is a security mechanism which allows HTTPS websites to resist impersonation by attackers using mis-issued or otherwise fraudulent certificates.
This was standardized in `}<a href="http://tools.ietf.org/html/rfc7469" target="_blank">{`RFC 7469`}</a>{` and creates a new opportunity for server validation.
Instead of using static certificate pinning, where public key hashes are hardcoded within an application, we can now use a more dynamic way of providing this public key hashes.
One caveat to remember is that HPKP uses a Trust On First Use (`}<a href="https://en.wikipedia.org/wiki/Trust_on_first_use" target="_blank">{`TOFU`}</a>{`) technique.`}</p>
    <h2>{`How does this work?`}</h2>
    <p>{`A list of public key hashes will be served to the client via a special HTTP header by the web server, so clients can store this information for a given period of time.
On subsequent connections within previous given period of time, the client expects a certificate containing a public key whose fingerprint is already known via HPKP.
I `}<strong parentName="p">{`strongly`}</strong>{` encourage you to read `}<a href="https://timtaubert.de/blog/2014/10/http-public-key-pinning-explained/" target="_blank">{`this article`}</a>{` by Tim Taubert, where he explains what keys you should pin and what the different tradeoffs are.`}</p>
    <p>{`Imagine you want to terminate the connection between the client and a malicious server for your main domain and all of your subdomains, but also want to be notified when such events happen.
In the next paragraph you can find the implementation details.`}</p>
    <p>{`The web server needs to send following header to the connecting client with the first response`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Public-Key-Pins:
    max-age=5184000;
    pin-sha256="d6qzRu9zOECb90Uez27xWltNsj0e1Md7GkYYkVoZWmM=";
    pin-sha256="E9CZ9INDbd+2eRQozYqqbQ2yXLVKB9+xcprMF+44U1g=";
    report-uri="https://example.net/hpkp-report";
    includeSubdomains
`}</code></pre>
    <p>{`By specifying the `}<strong parentName="p">{`Public-Key-Pins`}</strong>{` header the client MUST terminate the connection without allowing the user to proceed anyway.
In this example, `}<strong parentName="p">{`pin-sha256="d6qzRu9zOECb90Uez27xWltNsj0e1Md7GkYYkVoZWmM="`}</strong>{` pins the server's public key used in production.
The second pin declaration `}<strong parentName="p">{`pin-sha256="E9CZ9INDbd+2eRQozYqqbQ2yXLVKB9+xcprMF+44U1g="`}</strong>{` also pins the backup key.
`}<strong parentName="p">{`max-age=5184000`}</strong>{` tells the client to store this information for two month, which is a reasonable time limit according to the IETF RFC.
This key pinning is also valid for all subdomains, which is told by the `}<strong parentName="p">{`includeSubdomains`}</strong>{` declaration.
Finally, `}<strong parentName="p"><inlineCode parentName="strong">{`report-uri="https://www.example.net/hpkp-report"`}</inlineCode></strong>{` explains where to report pin validation failures.`}</p>
    <h2>{`So how can we implement this with Spring Security?`}</h2>
    <h3>{`Retrieving  the list of public key hashes`}</h3>
    <p>{`We first need to get a list of public key hashes.
Currently the standard only supports the SHA256 hashing algorithm.
The following commands will help you extract the Base64 encoded information:`}</p>
    <h5>{`From a key file`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`openssl rsa -in my-key-file.key -outform der -pubout | openssl dgst -sha256 -binary | openssl enc -base64
`}</code></pre>
    <h5>{`From a Certificate Signing Request (CSR)`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`openssl req -in my-signing-request.csr -pubkey -noout | openssl rsa -pubin -outform der | openssl dgst -sha256 -binary | openssl enc -base64
`}</code></pre>
    <h5>{`From a certificate`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`openssl x509 -in my-certificate.crt -pubkey -noout | openssl rsa -pubin -outform der | openssl dgst -sha256 -binary | openssl enc -base64
`}</code></pre>
    <h5>{`From a running web server`}</h5>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`openssl s_client -servername www.example.com -connect www.example.com:443 | openssl x509 -pubkey -noout | openssl rsa -pubin -outform der | openssl dgst -sha256 -binary | openssl enc -base64
`}</code></pre>
    <p>{`For now we will assume we got 2 public keys:`}</p>
    <ul>
      <li parentName="ul">{`Our active production certificate: `}<inlineCode parentName="li">{`d6qzRu9zOECb90Uez27xWltNsj0e1Md7GkYYkVoZWmM=`}</inlineCode></li>
      <li parentName="ul">{`Our backup production certificate: `}<inlineCode parentName="li">{`E9CZ9INDbd+2eRQozYqqbQ2yXLVKB9+xcprMF+44U1g=`}</inlineCode></li>
    </ul>
    <h3>{`Configuring Spring Security`}</h3>
    <p>{`As of version `}<strong parentName="p">{`4.1.0.RC1`}</strong>{`, which will be released March 24th 2016, the `}<inlineCode parentName="p">{`HpkpHeaderWriter`}</inlineCode>{` has been added to the security module.
The 2 easiest ways to implement this feature is either by `}<strong parentName="p">{`Java configuration`}</strong>{` or by using the older, but still supported, `}<strong parentName="p">{`XML configuration`}</strong>{`.
Below you can find both solutions:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@EnableWebSecurity
public class HpkpConfig extends WebSecurityConfigurerAdapter {
    @Override
    protected void configure(HttpSecurity http) throws Exception {
        http.httpPublicKeyPinning()
            .addSha256Pins("d6qzRu9zOECb90Uez27xWltNsj0e1Md7GkYYkVoZWmM=", "E9CZ9INDbd+2eRQozYqqbQ2yXLVKB9+xcprMF+44U1g=")
            .reportOnly(false)
            .reportUri("http://example.net/hpkp-report")
            .includeSubDomains(true);
    }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-xml"
      }}>{`<http>
    <!-- ... -->

    <headers>
        <hpkp
            report-only="false"
            report-uri="http://example.net/hpkp-report"
            include-subdomains="true">
            <pins>
                <pin>d6qzRu9zOECb90Uez27xWltNsj0e1Md7GkYYkVoZWmM=</pin>
                <pin>E9CZ9INDbd+2eRQozYqqbQ2yXLVKB9+xcprMF+44U1g=</pin>
            </pins>
        </hpkp>
    </headers>
</http>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      